import * as React from "react"

import Layout from "../components/layout/layout"
import HeroSectionNew from "../components/sections/hero-section-new"
import Seo from "../components/seo"
import PageShopifyListSectionUpdate from "../components/sections/page-shopify-list-section-update"

const ShopifyPage = () => {
  return (
    <Layout>
      <Seo title="Best Shopify Themes" />

      <HeroSectionNew subTitle="Best Shopify Themes" />

      <PageShopifyListSectionUpdate />
    </Layout>
  )
}

export default ShopifyPage
